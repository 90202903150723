import * as luxon from "luxon";

import data from "./data";
import { Flight as TFlight } from "./data/bindings/Flight";

import * as airports from "./airports";
import { OverrideFields } from "./util";

export type Flight = OverrideFields<
    TFlight,
    {
        from: airports.Airport;
        to: airports.Airport;
        departure: luxon.DateTime;
        arrival: luxon.DateTime;
    }
>;

export const all = data.flights.map((flight): Flight => {
    const from = airports.fromICAO(flight.from_icao);
    const to = airports.fromICAO(flight.to_icao);

    const departure = luxon.DateTime.fromISO(flight.departure, {
        zone: from.timezone,
    });

    const arrival = luxon.DateTime.fromISO(flight.arrival, {
        zone: to.timezone,
    });

    return { ...flight, from, to, departure, arrival };
});

// TODO: get actual flight paths from flightradar or flightaware?
