import * as luxon from "luxon";
import _ from "lodash";

export type { OverlandTrip } from "./data/bindings/OverlandTrip";

import { OverlandTrip } from "./data/bindings/OverlandTrip";
import data from "./data";

export function tripKey(d: OverlandTrip) {
    return d.name;
}

export function trackKey(d: OverlandTrip, date: luxon.DateTime) {
    return `${tripKey(d)}-${date}`;
}

const lastTrip = _.minBy(data.overland_trips, (t) =>
    _.min(t.tracks.map((t) => t.date))
)!;

const lastDate = _.min(lastTrip.tracks.map((t) => t.date))!;

export const all: OverlandTrip[] = [
    {
        ...lastTrip,
        tracks: lastTrip.tracks.filter((t) => t.date === lastDate),
    },
];

console.log({ lastTrip, lastDate, all });
