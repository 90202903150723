import * as React from "react";
import mapboxgl from "mapbox-gl";

import Map from "./Map";
import "./App.css";

mapboxgl.accessToken = process.env.MAPBOX_ACCESS_TOKEN!;

function App() {
    function getWindowSize() {
        return {
            width: window.innerWidth,
            height: window.innerHeight,
        };
    }

    const [windowSize, setWindowSize] = React.useState(getWindowSize());

    React.useEffect(() => {
        function resized() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener("resize", resized);
        return () => window.removeEventListener("resize", resized);
    });

    return (
        <div className="App">
            <Map width={windowSize.width} height={windowSize.height} />
        </div>
    );
}

export default App;
