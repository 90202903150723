import * as React from "react";
import update from "immutability-helper";
import * as map from "react-map-gl";
import geobuf from "geobuf";
import Pbf from "pbf";
import * as luxon from "luxon";
import * as turf from "@turf/turf";

import { Checkbox, Group } from "@mantine/core";

import "rc-tree/assets/index.css";

import data from "./data";

import * as overland from "./overland-trips";
import * as flights from "./flights";

import styles from "./Map.module.scss";

namespace Settings {
    type WhatToShow = "Flights" | "Drives";

    type WhatToHighlight = "VisitedCountries";

    type State = {
        show: WhatToShow[];
        highlight: WhatToHighlight[];
    };

    export const defaultState: State = {
        show: ["Flights", "Drives"],
        highlight: [],
    };

    export function SettingsPanel(props: {
        settings: State;
        setSettings: (state: State) => void;
    }) {
        // TODO: see if I can get rid of the type assertions

        return (
            <div className={styles.SettingsPanel}>
                <Checkbox.Group
                    value={props.settings.show}
                    onChange={(value) =>
                        props.setSettings(
                            update(props.settings, {
                                show: { $set: value as WhatToShow[] },
                            })
                        )
                    }
                    label="Show"
                >
                    <Group>
                        <Checkbox value="Flights" label="Flights" />
                        <Checkbox value="Drives" label="Drives" />
                    </Group>
                </Checkbox.Group>

                <Checkbox.Group
                    value={props.settings.highlight}
                    onChange={(value) =>
                        props.setSettings(
                            update(props.settings, {
                                highlight: { $set: value as WhatToHighlight[] },
                            })
                        )
                    }
                    label="Highlight"
                >
                    <Group>
                        <Checkbox
                            value="VisitedCountries"
                            label="Visited Countries"
                        />
                    </Group>
                </Checkbox.Group>
            </div>
        );
    }
}

async function loadPbf(data: URL) {
    return geobuf.decode(new Pbf(await (await fetch(data)).arrayBuffer()));
}

interface SelectedFeature_<Type extends string, Datum> {
    event: MouseEvent;
    type: Type;
    datum: Datum;
}

type SelectedFeature =
    | SelectedFeature_<"Flight", flights.Flight>
    | SelectedFeature_<"Drive", overland.OverlandTrip>;

// function SelectedFeatureTooltip(props: {
//     projection: d3.GeoProjection;
//     feature: SelectedFeature | null;
// }) {
//     switch (props.feature?.type) {
//         case "Flight":
//             function AirportIcon(innerProps: { airport: airports.Airport }) {
//                 const position = props.projection(innerProps.airport.position);

//                 if (position === null) return null;

//                 const [left, top] = position;
//                 return (
//                     <div className={styles.airportIcon} style={{ top, left }}>
//                         <img src={airportIcon} />
//                         <div className={styles.airportIconCode}>
//                             {innerProps.airport.iata}
//                         </div>
//                     </div>
//                 );
//             }

//             function FlightsOnRoute(props: {
//                 from: airports.Airport;
//                 to: airports.Airport;
//             }) {
//                 const onRoute = flights.all.filter(
//                     (f) => f.from === props.from && f.to === props.to
//                 );

//                 function localDayDiff(a: luxon.DateTime, b: luxon.DateTime) {
//                     const dateA = luxon.DateTime.utc(a.year, a.month, a.day);
//                     const dateB = luxon.DateTime.utc(b.year, b.month, b.day);

//                     return dateA.diff(dateB).as("days");
//                 }

//                 if (onRoute.length === 0) return null;

//                 return (
//                     <div>
//                         <div>
//                             <span>{props.from.iata}</span>
//                             <span> - </span>
//                             <span>{props.to.iata}</span>
//                         </div>
//                         {onRoute.map((f, i) => {
//                             const extraDays = localDayDiff(
//                                 f.arrival,
//                                 f.departure
//                             );

//                             return (
//                                 <div key={i}>
//                                     <span>
//                                         {f.departure.toFormat("yyyy-LL-dd")}
//                                     </span>
//                                     <span>{f.departure.toFormat("HH:mm")}</span>
//                                     <span> - </span>
//                                     <span>
//                                         {f.arrival.toFormat("HH:mm")}
//                                         <sup>
//                                             {extraDays > 0 && `(+${extraDays})`}
//                                         </sup>
//                                     </span>
//                                 </div>
//                             );
//                         })}
//                     </div>
//                 );
//             }

//             const selected = props.feature.datum;
//             const [left, right] = util.sort(
//                 [selected.from, selected.to],
//                 (a) => a.position
//             );

//             return (
//                 <>
//                     <AirportIcon airport={left} />
//                     <AirportIcon airport={right} />
//                     {/*
//                     <div className={styles.selectedFeatureTooltip}>
//                         <span>
//                             {`${Math.round(
//                                 turf.distance(left.position, right.position)
//                             )} mi`}
//                         </span>
//                         <FlightsOnRoute from={left} to={right} />
//                         <FlightsOnRoute from={right} to={left} />
//                     </div> */}
//                 </>
//             );

//         case "Drive":
//             // TODO: unsupported
//             return <div></div>;
//     }

//     return null;
// }

function visibility(b: boolean) {
    return b ? "visible" : "none";
}

function Flights(props: { visible: boolean }) {
    const now = luxon.DateTime.now().toMillis();

    const geojson = turf.featureCollection(
        flights.all
            // .filter((f) => f.path)
            .map((f) => {
                let properties = {
                    departure: f.departure.toMillis(),
                };

                return f.path
                    ? turf.lineString(f.path, properties)
                    : turf.greatCircle(f.from.position, f.to.position, {
                          properties,
                      });
            })
    );

    return (
        <map.Source id="flights" type="geojson" data={geojson}>
            <map.Layer
                id="flights-past"
                type="line"
                layout={{
                    visibility: visibility(props.visible),
                    "line-join": "round",
                    "line-cap": "round",
                }}
                paint={{
                    "line-color": "red",
                    "line-width": 2,
                }}
                filter={["<=", "departure", now]}
            />
            <map.Layer
                id="flights-future"
                type="line"
                layout={{
                    visibility: visibility(props.visible),
                    "line-join": "round",
                    "line-cap": "round",
                }}
                paint={{
                    "line-color": "orange",
                    "line-width": 2,
                }}
                filter={[">", "departure", now]}
            />
        </map.Source>
    );
}

function Drives(props: { visible: boolean }) {
    let allTracks = overland.all.flatMap((trip) => trip.tracks);

    const drivesGeojson = turf.featureCollection(
        allTracks
            .filter((t) => t.type_ === "Drive")
            .map((t) => turf.lineString(t.path))
    );

    const transitGeojson = turf.featureCollection(
        allTracks
            .filter((t) => t.type_ === "Transit")
            .map((t) => turf.lineString(t.path))
    );

    return (
        <>
            <map.Source id="drives" type="geojson" data={drivesGeojson}>
                <map.Layer
                    id="drives"
                    type="line"
                    layout={{
                        visibility: visibility(props.visible),
                        "line-join": "round",
                        "line-cap": "round",
                    }}
                    paint={{
                        "line-color": "green",
                        "line-width": 2,
                    }}
                />
            </map.Source>

            <map.Source id="transit" type="geojson" data={transitGeojson}>
                <map.Layer
                    id="transit"
                    type="line"
                    layout={{
                        visibility: visibility(props.visible),
                        "line-join": "round",
                        "line-cap": "round",
                    }}
                    paint={{
                        "line-color": "blue",
                        "line-width": 2,
                    }}
                />
            </map.Source>
        </>
    );
}

function VisitedCountries(props: { visible: boolean }) {
    return (
        <map.Source
            id="visitedCountries"
            type="vector"
            url="mapbox://mapbox.country-boundaries-v1"
        >
            <map.Layer
                id="visitedCountries"
                source-layer="country_boundaries"
                type="fill"
                layout={{
                    visibility: visibility(props.visible),
                }}
                paint={{
                    "fill-color": "rgba(66,100,251, 0.3)",
                    "fill-outline-color": "blue",
                }}
                filter={["in", "iso_3166_1_alpha_3", ...data.visited_countries]}
            />
        </map.Source>
    );
}

export default function (props: { width: number; height: number }) {
    const [mapViewState, setMapViewState] = React.useState({
        // London
        // longitude: 0.1276,
        // latitude: 51.5072,
        // New York
        longitude: -74.006,
        latitude: 40.7128,
        zoom: 5,
    });

    const [settings, setSettings] = React.useState(Settings.defaultState);

    // TODO: include drives too
    // const visitedCountries = new Set();
    // for (const f of flights.all) {
    //     for (const c of admin0Geojson.features) {
    //         if (
    //             turf.booleanPointInPolygon(f.from.position, c) ||
    //             turf.booleanPointInPolygon(f.to.position, c)
    //         ) {
    //             visitedCountries.add(c);
    //         }
    //     }
    // }

    // const [checkedTracks, setCheckedTracks] = React.useState<Set<string>>(
    //     new Set(
    //         drives.all.flatMap((d) =>
    //             [...d.tracks.entries()].map(([date, t]) =>
    //                 drives.trackKey(d, date)
    //             )
    //         )
    //     )
    // );

    //     let drivesToShow: drives.Drive[] = drives.all
    //         .map((d) => {
    //             let tracks = [...d.tracks.entries()].filter(([date, track]) =>
    //                 checkedTracks.has(drives.trackKey(d, date))
    //             );

    //             return { ...d, tracks: new Map(tracks) };
    //         })
    //         .filter((d) => d.tracks.size > 0);

    return (
        <>
            {/* <FilterInput />
            <SelectedFeatureTooltip
                projection={projection}
                feature={selectedFeature}
            /> */}

            {/* <DebugTree
                drives={drives.all}
                onCheck={(checked) => setCheckedTracks(new Set(checked))}
            /> */}

            <Settings.SettingsPanel
                settings={settings}
                setSettings={setSettings}
            />

            <div className={styles.Map}>
                <map.Map
                    {...mapViewState}
                    onMove={(evt) => setMapViewState(evt.viewState)}
                    mapStyle="mapbox://styles/mapbox/streets-v12"
                    projection={{ name: "globe" }}
                    styleDiffing
                >
                    <Flights visible={settings.show.includes("Flights")} />
                    <Drives visible={settings.show.includes("Drives")} />
                    <VisitedCountries
                        visible={settings.highlight.includes(
                            "VisitedCountries"
                        )}
                    />
                </map.Map>
            </div>
        </>
    );
}
